<template>

    <div v-if="loading" class="d-flex justify-content-center align-items-center">
        <Loader/>
    </div>

    <router-view v-else-if="widget"/>

    <template v-else>
        <Header/>

        <Sidebar/>

        <div class="main-content-wrapper">
            <router-view/>
        </div>

        <div class="alert-container">
            <TransitionGroup name="list-complete">
                <div v-for="(alert) of alerts" :key="alert.id">
                    <Alert @close="alerts.splice(alerts.indexOf(alert), 1)" :class="alert.class">
                        <component v-if="alert.icon" :is="alert.icon" class="me-2"/>
                        <div v-html="alert.content"></div>
                    </Alert>
                </div>
            </TransitionGroup>
        </div>

        <Footer/>

        <Confirmable :confirm="confirm"/>
    </template>

</template>

<script>
import "@/style/fix.css";
import {parents, tools} from "@/tools";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import Alert from "@/components/Alert.vue";
import BellIcon from "@/components/icons/BellIcon.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import Confirmable from "@/components/Confirmable.vue";
import Loader from "@/components/Loader.vue";

export default {
    name: "App",
    components: {Loader, Confirmable, Sidebar, Alert, Footer, Header},
    data: () => ({
        loading: true,

        widget: false,

        tools,
        tool_parents: parents,
        known_tools: [],
        const: {
            turnstile_site_key: '0x4AAAAAAANOd9TRR8PMqxAQ'
        },

        _alert_id: 1,
        alerts: [],
        confirm: null,

        user: null,
        auth: null,

        body: {
            width: 1920,
            height: 1080,
            scroll: 0
        },
    }),
    computed: {
        nested_tools: function() {
            const output = {...parents};
            for(const [parent_id, parent] of Object.entries(output)){
                parent.children = Object.values(this.tools).filter((tool) => tool.parent === parent_id);
            }
            return output;
        }
    },
    watch: {
        'known_tools': function(tools){
            this.saveLocalConfig('known_tools', tools);
        },
        '$route.name': function(){
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    },
    methods: {
        insertKnownTool(tool_id){
            this.known_tools = [...this.known_tools, tool_id];
        },

        confirmAction(data){
            this.confirm = data;
        },

        alert(content, config = {}){
            this.alerts = [{
                id: this._alert_id++,
                content,
                icon: BellIcon,
                ...config
            }, ...this.alerts]
        },

        initAuthentication(){
            const auth = this.getLocalConfig('authentication');
            if(auth){
                this.verifyAuthentication(auth)
            } else {
                this.removeAuthentication();
            }
        },
        async verifyAuthentication(auth){
            const r = await this.apiRequest(`https://api.pikatool.com/v1/me`, {
                headers: {
                    'Authorization': `Bearer ${auth.access_token}`
                }
            }).catch(error => console.log(error));

            if(r && r.success){
                this.saveLocalConfig('authentication', auth);
                this.auth = auth;
                this.user = r.data;
            } else {
                this.removeAuthentication();
            }
        },
        async removeAuthentication(){
            if(this.auth && this.auth.access_token){
                const r = await this.apiRequest(`https://auth.pikatool.com/token`, {method: 'DELETE'}).catch(error => console.log(error));
            }

            this.saveLocalConfig('authentication', null);
            this.auth = false;
            this.user = false;
        }
    },
    created() {
        // Scroll handler
        const onScroll = () => {
            this.body.scroll = window.scrollY;
        };
        document.addEventListener('scroll', onScroll);
        onScroll();

        // Resize handler
        const observer = new ResizeObserver(() => {
            this.body.width = window.innerWidth;
            this.body.height = window.innerHeight;
        });
        observer.observe(document.body);

        // Load Cache
        this.known_tools = this.getLocalConfig('known_tools', []);

        // Auth listener
        window.onmessage = (event) => {
            if(event.data && typeof event.data === 'object'){
                if(event.data.action && event.data.action === 'auth'){
                    if(event.data.auth && typeof event.data.auth === 'object'){
                        const auth = event.data.auth;
                        if(auth.access_token){
                            this.verifyAuthentication(auth);
                        }
                    }
                }
            }
        };
    },
    mounted() {
        if(this.$route.name === 'widget'){

            this.widget = true;

        } else {

            this.initAuthentication();

        }

        this.loading = false;
    }
}
</script>

<style>
.list-complete-move,
.list-complete-enter-active,
.list-complete-leave-active {
    transition: all 0.32s ease;
}
.list-complete-enter-from,
.list-complete-leave-to {
    opacity: 0;
    transform: scale(0.1) translateX(-100%);
}
.list-complete-leave-active {
    position: absolute;
}

.alert-container{
    position: fixed;
    z-index: 3;
    width: max(30%, 28rem);
    max-width: 100vw;
    left: 0;
    bottom: 0;
    padding: 0 2rem 2rem 2rem;
}

.inline-alert{
    padding: 0.8em;
    background-color: rgba(225,0,0,0.2);
    border-radius: 0.4em;
}
</style>
